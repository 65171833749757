import React, { useEffect, useState } from "react";
import Hero from "../controller/Hero";
import Products from "../controller/Products";
import Features from "../controller/Features";
import Statics from "../controller/Statics";
import ContactForm from "../controller/ContactForm";

function Home() {
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    fetch("https://fakestoreapi.com/products?limit=8")
      .then((response) => response.json())
      .then((data) => setProductList(data));
  }, []);

  return (
    <div>
      <Hero />
      <Features />
      <div className="container mx-auto">
        <div className="text-center mt-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Raw Denim Heirloom Man Braid
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-400 text-opacity-80">
            Blue bottle crucifix vinyl post-ironic four dollar toast vegan
            taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi
            pug.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
        </div>
      </div>
      <Products products={productList} />      
      <Statics />
      <ContactForm/>
    </div>
  );
}

export default Home;
