import { useState, useEffect, useContext } from "react";
import Products from "../controller/Products";

function Shop() {
  const [productList, setProductList] = useState([]);
  const url = "https://fakestoreapi.com/products";


  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setProductList(data));
  }, [url]);

  return (
    <>
      <div className=" mx-6">
        <div className="text-center mt-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
            Shop
          </h1>
          <div className="flex mt-3 justify-center">
            <div className="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
          <div className="mt-6  mx-auto">
              {productList.length === 0 ? (
                <div className="text-center mt-20 mb-20">Loading...</div>
              ) : (
                <Products products={productList} />
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop;
