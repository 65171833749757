import { useState, useEffect } from "react";
import Products from "../controller/Products";
import { Link, useParams } from "react-router-dom";

function Category() {
  const [productList, setProductList] = useState([]);

  const { name } = useParams();
  useEffect(() => {
    fetch(`https://fakestoreapi.com/products/category/${name}`)
      .then((response) => response.json())
      .then((data) => setProductList(data));
  }, []);
  return (
    <>
      <div className=" mx-6">
        <div className="text-center mt-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-2">

            {name.charAt(0).toUpperCase() + name.slice(1)}
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-400 text-opacity-80">
          <Link className="hover:text-black" to={"/"}>Home</Link> -{" "}
            {name.charAt(0).toUpperCase() + name.slice(1)}
          </p>
          <div className="flex mt-3 justify-center">
            <div className="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
          <div className="flex mt-6 ">
            <div className="w-1/5 px-5">
              <h2 className="sm:text-3xl text-xl font-small text-gray-900 mb-4 text-left">
                Filter
              </h2>
              <h3 className="text-base leading-relaxed text-left">
                Categories
              </h3>
            </div>
            <div className="w-4/5">
              {productList.length === 0 ? (
                <div className="text-center mt-20 mb-20">Loading...</div>
              ) : (
                <Products products={productList} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
