import React, { useEffect, useState } from 'react';
import './App.css';
import {Routes,Route} from 'react-router-dom';
import Header from './controller/Header';
import Home from './modules/Home';
import Footer from './controller/Footer';
import Product from './modules/Product';
import NotFound from './controller/NotFound';
import Shop from './modules/Shop';
import Category from './modules/Category';
import Cart from './modules/Cart';
import ContactUs from './modules/ContactUs';


function App() {

    const [cartData, setCartData] = useState([]);
  
    const handleDataFromChild = (data) => {
      setCartData(data);
    };
    const cartVal= (data) => {
      setCartData(data);
    };

  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/product/:id' element={<Product  cartValue={cartData}  cartData={handleDataFromChild}/>}></Route>
        <Route path='/product' element={<Shop/>}/>
        <Route path='/contact_us' element={<ContactUs/>}/>
        <Route path='/categories/:name' element={<Category/>}/>
        <Route path='/cart' element={<Cart cartValue={cartData} cartDataVal={cartVal} />}/>
        <Route  path="*" element={<NotFound/>}></Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
