import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotFound from "./NotFound";

function SingleProduct({ cartValue }) {
  const { id } = useParams();
  const [productData, setProduct] = useState({});
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const url = `https://fakestoreapi.com/products/${id}`;
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => setProduct(json))
      .catch((error) => {
        return setError(true);
      });
  }, [id]);

  //rating star
  const rating = [];
  if (Object.keys(productData).length > 0) {
    for (let i = 1; i < 6; i++) {
      rating.push(
        <svg
          key={i}
          fill={
            i <= Math.round(productData.rating.rate) ? "currentColor" : "none"
          }
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-4 h-4 text-purple-500"
          viewBox="0 0 24 24"
        >
          <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
        </svg>
      );
    }
  }

  //handle Cart
  const handleCart = (product, redirect) => {
    cartValue(product);
    if (redirect) {
      setTimeout(() => {
        navigate("/cart");
      }, 100);
    } else {
      alert("Product Added to Cart");
    }
  };

  if (Object.keys(productData).length === 0) return <div>Loading...</div>;

  if (error) {
    return <NotFound />;
  }

  return (
    Object.keys(productData).length > 0 && (
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="py-4">
            <Link to={'/'}
            className="text-sm title-font text-gray-500 tracking-widest"
            >HOME</Link> <span>{` > `}</span>
          <Link
                to={`/categories/${productData.category}`}
                className="text-sm title-font text-gray-500 tracking-widest"
              >
                {Object.keys(productData).length > 0 &&
                  productData.category.toUpperCase()}
              </Link>
          </div>
          <div className="lg:w-4/5 mx-auto flex flex-wrap h-[400px]">
            <img
              alt={productData.title}
              className="max-w-md lg:w-1/2 w-full h-[100%]  object-contain object-center rounded shadow-2xl py-4"
              src={productData.image}
            />
            <div className="lg:w-1/2 w-full lg:pl-24 lg:py-6 mt-6 lg:mt-0 ">
              <Link
                to={`/categories/${productData.category}`}
                className="text-sm title-font text-gray-500 tracking-widest"
              >
                {Object.keys(productData).length > 0 &&
                  productData.category.toUpperCase()}
              </Link>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                {productData.title}
              </h1>
              <div className="flex mb-4">
                <span className="flex items-center">
                  {rating}
                  <span className="text-gray-600 ml-3">
                    {productData.rating.count} Reviews
                  </span>
                </span>

                <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 space-x-2s">
                  <a className="text-gray-500">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </a>
                  <a className="text-gray-500">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                  </a>
                  <a className="text-gray-500">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                    </svg>
                  </a>
                </span>
              </div>
              <p className="leading-relaxed">{productData.description}</p>
              <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5"></div>
              <div className="flex justify-between">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Rs. {productData.price}
                </span>
                <div className="flex">
                  <button
                    className="flex ml-auto text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded mx-2"
                    onClick={() => handleCart(productData, true)}
                  >
                    Buy Now
                  </button>
                  <button
                    className="flex ml-auto text-black bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded mx-2"
                    onClick={() => handleCart(productData, false)}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
}

export default SingleProduct;
