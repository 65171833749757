import React from "react";
import { Link } from "react-router-dom";

function Cart({ cartValue, cartDataVal }) {

  const handleRemove = (index) => {
    const temp = cartValue.filter((data, i) => i !== index);
    cartDataVal(temp);
  };
  const dec = (index,quant) => {
    if(quant >1){
      const temp = cartValue.map((data, i) =>
       i === index ? { ...data, quantity: data.quantity - 1 } : data
    );
    cartDataVal(temp);
    }
    else{
      handleRemove(index);
    }
    
  };

  const inc = (index) => {
    const temp = cartValue.map((data, i) =>
      i === index ? { ...data, quantity: data.quantity + 1 } : data
    );
    cartDataVal(temp);
  };

  const totalProduct = cartValue.reduce((acc,val) => {
    return acc=+acc + +val.quantity
  },[0]);
  const totalCost = cartValue.reduce((acc,val) => {
    return acc=+acc + +val.quantity*val.price
  },[0]);


  if(cartValue.length===0 ){
  return(
    <div className="container mx-auto my-20">
      <div className=" shadow-md my-10">
            <h2 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 px-8 my-4">Cart is Empty</h2>
            <Link to={'/product'} className="inline-flex text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded text-lg mx-8 mb-4">Return to Shop</Link>
      </div>
</div>    
    )
  }

  return (
    <div className="container mx-auto my-20">
      <div className="flex shadow-md my-10">
        <div className="w-3/4 bg-white px-10 py-10">
          <div className="flex justify-between border-b pb-8">
            <h1 className="font-semibold text-2xl">Shopping Cart</h1>
            <h2 className="font-semibold text-2xl">{totalProduct} Items</h2>
          </div>
          <div className="flex mt-10 mb-5">
            <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/5">
              Product Details
            </h3>
            <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
              Quantity
            </h3>
            <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
              Price
            </h3>
            <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
              Total
            </h3>
          </div>
          {cartValue.length > 0 &&
            cartValue.map((product, index) => (
              <div
                key={index}
                className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5"
              >
                <div className="flex w-2/5">
                  <div className="w-20">
                    <img className="h-24" src={product.image} alt="" />
                  </div>
                  <div className="flex flex-col justify-between ml-4 flex-grow">
                    <Link to={`/product/${product.id}`} className="font-bold text-sm">{product.title}</Link>
                    <Link to={`/categories/${product.category}`} className="text-red-500 text-xs">
                      {product.category}
                    </Link>
                    <div
                      onClick={() => handleRemove(index)}
                      href="#"
                      className="font-semibold hover:text-red-500 text-gray-500 text-xs cursor-pointer"
                    >
                      Remove
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-1/5">
                  <svg
                    className="fill-current text-gray-600 w-3 cursor-pointer"
                    viewBox="0 0 448 512"
                    onClick={() => dec(index,product.quantity)}
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                  </svg>

                  <input
                    className="mx-2 border text-center w-8"
                    type="text"
                    value={product.quantity}
                    readOnly
                  />

                  <svg
                    className="fill-current text-gray-600 w-3 cursor-pointer"
                    viewBox="0 0 448 512"
                    onClick={() => inc(index)}
                  >
                    <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                  </svg>
                </div>
                <span className="text-center w-1/5 font-semibold text-sm">
                  Rs. {product.price}
                </span>
                <span className="text-center w-1/5 font-semibold text-sm">
                  Rs. {(product.price * product.quantity).toFixed(2)}
                </span>
              </div>
            ))}

          <Link
            to={"/product"}
            className="flex font-semibold text-indigo-600 text-sm mt-10"
          >
            <svg
              className="fill-current mr-2 text-indigo-600 w-4"
              viewBox="0 0 448 512"
            >
              <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
            </svg>
            Continue Shopping
          </Link>
        </div>
        <div id="summary" className="w-1/4 px-8 py-10">
          <h1 className="font-semibold text-2xl border-b pb-8">
            Order Summary
          </h1>
          <div className="flex justify-between mt-10 mb-5">
            <span className="font-semibold text-sm uppercase">Items {totalProduct}</span>
            <span className="font-semibold text-sm">Rs. {cartValue.length > 0 ? totalCost.toFixed(2) :'0' }</span>
          </div>
          <div>
            <label className="font-medium inline-block mb-3 text-sm uppercase">
              Shipping
            </label>
            <select className="block p-2 text-gray-600 w-full text-sm">
              <option>Standard shipping - $10.00</option>
            </select>
          </div>
          <div className="py-10">
            <label
              htmlFor="promo"
              className="font-semibold inline-block mb-3 text-sm uppercase"
            >
              Promo Code
            </label>
            <input
              type="text"
              id="promo"
              placeholder="Enter your code"
              className="p-2 text-sm w-full"
            />
          </div>
          <button className="bg-red-500 hover:bg-red-600 px-5 py-2 text-sm text-white uppercase">
            Apply
          </button>
          <div className="border-t mt-8">
            <div className="flex font-semibold justify-between py-6 text-sm uppercase">
              <span>Total cost</span>
              <span>Rs. {cartValue.length > 0 ? totalCost.toFixed(2) :'0' }</span>
            </div>
            <button className="inline-flex items-center text-white bg-purple-500 border-0 py-1 px-3 focus:outline-none hover:bg-purple-700 rounded text-base mt-4 md:mt-0">
              Checkout
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-1"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
