import React from "react";
import { Link } from "react-router-dom";

function Products({ products }) {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {products.map((data, index) => (
            <Link to={`/product/${data.id}`} key={index} className="lg:w-1/4 md:w-1/2 p-4  mb-12 w-full">
              <div className="w-11/12 p-4 shadow-lg mx-auto flex flex-wrap h-full ease-in duration-300 hover:scale-125">
                <div className="block relative h-48 rounded overflow-hidden w-full">
                  <img
                    alt={data.title}
                    className="object-center object-contain w-full h-full block"
                    src={data.image}
                  />
                </div>
                <div className="mt-4 w-full">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                    {data.category}
                  </h3>
                  <h2 className="text-gray-900 title-font text-lg font-medium">
                    {data.title}
                  </h2>
                  <p className="mt-1">Rs. {data.price}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Products;
