import React, { useEffect, useState } from 'react'
import SingleProduct from '../controller/SingleProduct'

function Product({cartData,cartValue}) {
   
  const [data,setData] = useState(cartValue);
  const handleDataCart = (value) => {
    const exist = data.find(search => search.id ===value.id);
    if(exist){
      const temp = data.map((val,index) => (
        val.id === value.id ? {...val,quantity:val.quantity+1} : val
      ))
      setData(temp)
    }
    else{
      const temp ={...value,quantity:1}
      setData(prev => [...prev,temp]);
    }
  };
  useEffect(() => {
    cartData(data)
  }, [data]);
  return (
    <>
    <SingleProduct cartValue={handleDataCart}/>
    </>
  )
}

export default Product
