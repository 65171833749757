import React from 'react'

function NotFound() {
  return (
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto h-96">
      <h1 className="flex-grow text-2xl font-medium title-font text-gray-900 text-center">Not Found</h1>
    </div>
  </div>
</section>
  )
}

export default NotFound
