import React from 'react'
import ContactForm from '../controller/ContactForm'

function ContactUs() {
  return (
    <>
    <ContactForm/>
    </>
  )
}

export default ContactUs
